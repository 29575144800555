import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import { Formik } from "formik"
import * as Yup from "yup"
import { getParam } from "../../utils"

const SignUpFormExtended = ({ source = "" }) => {
  let [formState, setFormState] = useState({
    state: "notSubmitted",
    response: false,
    error: false,
  })

  let gclid = useMemo(() => {
    if (typeof window !== "undefined") {
      return getParam("gclid") || window.localStorage.getItem("gclid") || null
    }
    return null
  }, [])

  let utm_source = useMemo(() => {
    if (typeof window !== "undefined") {
      return (
        getParam("utm_source") ||
        window.localStorage.getItem("utm_source") ||
        null
      )
    }
    return null
  }, [])

  let utm_medium = useMemo(() => {
    if (typeof window !== "undefined") {
      return (
        getParam("utm_medium") ||
        window.localStorage.getItem("utm_medium") ||
        null
      )
    }
    return null
  }, [])

  let utm_campaign = useMemo(() => {
    if (typeof window !== "undefined") {
      return (
        getParam("utm_campaign") ||
        window.localStorage.getItem("utm_campaign") ||
        null
      )
    }
    return null
  }, [])

  let utm_term = useMemo(() => {
    if (typeof window !== "undefined") {
      return (
        getParam("utm_term") || window.localStorage.getItem("utm_term") || null
      )
    }
    return null
  }, [])

  let utm_content = useMemo(() => {
    if (typeof window !== "undefined") {
      return (
        getParam("utm_content") ||
        window.localStorage.getItem("utm_content") ||
        null
      )
    }
    return null
  }, [])

  const SignUpSchema = Yup.object().shape({
    email: Yup.string()
      .max(100, "Cannot be longer than 32 characters")
      .email("Invalid email")
      .required("Required"),
    username: Yup.string()
      .min(4, "Must be at least 4 characters")
      .max(32, "Cannot be longer than 32 characters")
      .matches(
        /^[a-zA-Z0-9@.]{4,}$/,
        "Only letters, numbers, and @ or . allowed"
      )
      .required("Required"),
    password: Yup.string()
      .min(6, "Must be at least 6 characters")
      .max(25, "Cannot be longer than 25 characters")
      .required("Required"),
    name: Yup.string()
      .matches(/^[A-Za-z0-9]/, "Only letters and numbers")
      .required("Required"),
    company: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    trucksDrivers: Yup.string().required("Required"),
    calls: Yup.string().required("Required"),
  })

  const handleSubmit = (values) => {
    axios
      .post("/api/website/signup", {
        ...values,
        referer: window.localStorage.getItem("referer"),
      })
      .then(
        (res) => {
          trackCapterra()
          trackGoogleConversion()
          setFormState({ state: "success", response: res })
        },
        (err) => {
          setFormState({ state: "error", error: err.response.data.message })
        }
      )
  }

  const trackCapterra = () => {
    let capterra_vkey = "0c04e0a3d0867620c2e9721835936950"
    let capterra_vid = "2061202"
    let script = document.createElement("script")

    script.src = `https://ct.capterra.com/capterra_tracker.js?vid=${capterra_vid}&vkey=${capterra_vkey}`
    script.id = "trackCapterra"

    document.body.appendChild(script)
  }

  const trackGoogleConversion = () => {
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-1069872843/B5wcCN-Z4BoQy-2T_gM",
        value: 1.0,
        currency: "USD",
      })
    }
  }

  let computedFormState = () => {
    switch (formState.state) {
      case "success":
        return (
          <div className="max-w-3xl p-6 mx-auto bg-gray-900 rounded-lg lg:p-8 rich-text">
            <h3>Check your email...</h3>
            <p>
              You should have an email waiting in your inbox from Towbook with a
              link to login.
            </p>
            <p>
              If the confirmation message from us hasn&apos;t arrived in your
              inbox within a few minutes, try checking your Junk Mail folder. In
              most cases, our messages are delivered within a few minutes.
            </p>
            <p>
              If you haven&apos;t received the email, please give us a call at{" "}
              <a href="tel:+18103205063">(810) 320-5063</a>. We answer our
              phones 24/7/365.
            </p>
            <p>
              From the Towbook team, thank you for your interest in Towbook
              &ndash; we look forward to working with you.
            </p>
          </div>
        )
      case "error":
      case "notSubmitted":
        return (
          <>
            {"error" === formState.state ? (
              <div className="max-w-3xl p-6 mb-6 bg-gray-900 rounded-lg lg:mb-8 lg:p-8 rich-text">
                <h3>There was a problem with your submission</h3>
                <p>
                  {formState.error ||
                    `Please try again later or feel free to give us a call!`}
                </p>
              </div>
            ) : (
              false
            )}

            <Formik
              initialValues={{
                email: "",
                username: "",
                password: "",
                name: "",
                company: "",
                phone: "",
                trucksDrivers: "",
                calls: "",
                motorClubCalls: false,
                bodyshopDealerships: false,
                policeCalls: false,
                impoundsStorage: false,
                privateProperty: false,
                source: source || "",
                gclid: gclid || "",
                utm_source: utm_source || "",
                utm_medium: utm_medium || "",
                utm_campaign: utm_campaign || "",
                utm_term: utm_term || "",
                utm_content: utm_content || "",
              }}
              validationSchema={SignUpSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form
                  className="flex flex-col lg:max-w-xl form"
                  onSubmit={handleSubmit}
                >
                  <div
                    className={`input-field ${
                      errors.email && touched.email ? "input-field--error" : ""
                    }`}
                  >
                    <label htmlFor="form-signup-email">Email</label>
                    <input
                      id="form-signup-email"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email ? (
                      <div className="mt-2 text-red-500">{errors.email}</div>
                    ) : (
                      false
                    )}
                  </div>

                  <div
                    className={`input-field ${
                      errors.username && touched.username
                        ? "input-field--error"
                        : ""
                    }`}
                  >
                    <label htmlFor="form-signup-username">Username</label>
                    <input
                      id="form-signup-username"
                      name="username"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                    {errors.username && touched.username ? (
                      <div className="mt-2 text-red-500">{errors.username}</div>
                    ) : (
                      false
                    )}
                  </div>

                  <div
                    className={`input-field ${
                      errors.password && touched.password
                        ? "input-field--error"
                        : ""
                    }`}
                  >
                    <label htmlFor="form-signup-password">Password</label>
                    <input
                      id="form-signup-password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {errors.password && touched.password ? (
                      <div className="mt-2 text-red-500">{errors.password}</div>
                    ) : (
                      false
                    )}
                  </div>

                  <div
                    className={`input-field ${
                      errors.name && touched.name ? "input-field--error" : ""
                    }`}
                  >
                    <label htmlFor="form-signup-fullname">Full name</label>
                    <input
                      id="form-signup-fullname"
                      name="name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name ? (
                      <div className="mt-2 text-red-500">{errors.name}</div>
                    ) : (
                      false
                    )}
                  </div>

                  <div
                    className={`input-field ${
                      errors.company && touched.company
                        ? "input-field--error"
                        : ""
                    }`}
                  >
                    <label htmlFor="form-signup-company">Company</label>
                    <input
                      id="form-signup-company"
                      name="company"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company}
                    />
                    {errors.company && touched.company ? (
                      <div className="mt-2 text-red-500">{errors.company}</div>
                    ) : (
                      false
                    )}
                  </div>

                  <div
                    className={`input-field ${
                      errors.phone && touched.phone ? "input-field--error" : ""
                    }`}
                  >
                    <label htmlFor="form-signup-phone">Phone</label>
                    <input
                      id="form-signup-phone"
                      name="phone"
                      type="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                    />
                    {errors.phone && touched.phone ? (
                      <div className="mt-2 text-red-500">{errors.phone}</div>
                    ) : (
                      false
                    )}
                  </div>

                  <div
                    className={`input-field ${
                      errors.trucksDrivers && touched.trucksDrivers
                        ? "input-field--error"
                        : ""
                    }`}
                  >
                    <label htmlFor="form-signup-trucksDrivers">
                      Trucks/Drivers
                    </label>
                    <select
                      id="form-signup-trucksDrivers"
                      name="trucksDrivers"
                      type="trucksDrivers"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.trucksDrivers}
                    >
                      <option value="" disabled>
                        Select number of trucks/drivers...
                      </option>
                      <option>1-19</option>
                      <option>20-39</option>
                      <option>40+</option>
                    </select>
                    {errors.trucksDrivers && touched.trucksDrivers && (
                      <div className="mt-2 text-red-500">
                        {errors.trucksDrivers}
                      </div>
                    )}
                  </div>

                  <div
                    className={`input-field ${
                      errors.calls && touched.calls ? "input-field--error" : ""
                    }`}
                  >
                    <label htmlFor="form-signup-calls">Calls</label>
                    <select
                      id="form-signup-calls"
                      name="calls"
                      type="calls"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.calls}
                    >
                      <option value="" disabled>
                        Select call quantity...
                      </option>
                      <option>0-250</option>
                      <option>251-500</option>
                      <option>501-1000</option>
                      <option>1001-1500</option>
                      <option>1501+</option>
                    </select>
                    {errors.calls && touched.calls && (
                      <div className="mt-2 text-red-500">{errors.calls}</div>
                    )}
                  </div>

                  <div className="mb-2">What type of work do you do?</div>

                  <div className="bg-gray-600 rounded">
                    <div
                      className={`checkbox-field ${
                        errors.motorClubCalls && touched.motorClubCalls
                          ? "checkbox-field--error"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor="form-signup-motorClubCalls"
                        className="flex items-center px-3 py-2"
                      >
                        <input
                          id="form-signup-motorClubCalls"
                          name="motorClubCalls"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.motorClubCalls}
                        />
                        <div className="ml-2">Motor Club Calls</div>
                      </label>
                      {errors.motorClubCalls && touched.motorClubCalls && (
                        <div className="mt-2 text-red-500">
                          {errors.motorClubCalls}
                        </div>
                      )}
                    </div>

                    <div
                      className={`checkbox-field ${
                        errors.bodyshopDealerships &&
                        touched.bodyshopDealerships
                          ? "checkbox-field--error"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor="form-signup-bodyshopDealerships"
                        className="flex items-center px-3 py-2"
                      >
                        <input
                          id="form-signup-bodyshopDealerships"
                          name="bodyshopDealerships"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.bodyshopDealerships}
                        />
                        <div className="ml-2">Bodyshop/Dealerships</div>
                      </label>
                      {errors.bodyshopDealerships &&
                        touched.bodyshopDealerships && (
                          <div className="mt-2 text-red-500">
                            {errors.bodyshopDealerships}
                          </div>
                        )}
                    </div>

                    <div
                      className={`checkbox-field ${
                        errors.policeCalls && touched.policeCalls
                          ? "checkbox-field--error"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor="form-signup-policeCalls"
                        className="flex items-center px-3 py-2"
                      >
                        <input
                          id="form-signup-policeCalls"
                          name="policeCalls"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.policeCalls}
                        />
                        <div className="ml-2">Police Calls</div>
                      </label>
                      {errors.policeCalls && touched.policeCalls && (
                        <div className="mt-2 text-red-500">
                          {errors.policeCalls}
                        </div>
                      )}
                    </div>

                    <div
                      className={`checkbox-field ${
                        errors.impoundsStorage && touched.impoundsStorage
                          ? "checkbox-field--error"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor="form-signup-impoundsStorage"
                        className="flex items-center px-3 py-2"
                      >
                        <input
                          id="form-signup-impoundsStorage"
                          name="impoundsStorage"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.impoundsStorage}
                        />
                        <div className="ml-2">Impounds/Storage</div>
                      </label>
                      {errors.impoundsStorage && touched.impoundsStorage && (
                        <div className="mt-2 text-red-500">
                          {errors.impoundsStorage}
                        </div>
                      )}
                    </div>

                    <div
                      className={`checkbox-field ${
                        errors.privateProperty && touched.privateProperty
                          ? "checkbox-field--error"
                          : ""
                      }`}
                    >
                      <label
                        htmlFor="form-signup-privateProperty"
                        className="flex items-center px-3 py-2"
                      >
                        <input
                          id="form-signup-privateProperty"
                          name="privateProperty"
                          type="checkbox"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          checked={values.privateProperty}
                        />
                        <div className="ml-2">Private Property</div>
                      </label>
                      {errors.privateProperty && touched.privateProperty && (
                        <div className="mt-2 text-red-500">
                          {errors.privateProperty}
                        </div>
                      )}
                    </div>
                  </div>

                  <input type="hidden" name="source" value={values.source} />
                  <input
                    type="hidden"
                    name="utm_source"
                    value={values.utm_source}
                  />
                  <input
                    type="hidden"
                    name="utm_medium"
                    value={values.utm_medium}
                  />
                  <input
                    type="hidden"
                    name="utm_campaign"
                    value={values.utm_campaign}
                  />
                  <input
                    type="hidden"
                    name="utm_term"
                    value={values.utm_term}
                  />
                  <input
                    type="hidden"
                    name="utm_content"
                    value={values.utm_content}
                  />

                  <button className="flex-1 block w-full px-4 py-2 mt-3 text-white bg-blue-500 rounded lg:w-auto lg:flex-initial lg:ml-auto hover:bg-blue-400">
                    Get Started
                  </button>
                </form>
              )}
            </Formik>
          </>
        )
    }
  }

  return computedFormState()
}

SignUpFormExtended.propTypes = {
  source: PropTypes.string,
}

export default SignUpFormExtended
